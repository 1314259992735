<template>
  <Steppers></Steppers>
</template>

<script>
import Steppers from "../../../views/app/Company/Steppers.vue";
export default {
  components: {
    Steppers,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
