<template>
  <Stepper :value="value" @change="handleChange" :items="items" />
</template>

<script>
import { Stepper } from "@progress/kendo-vue-layout";
export default {
  components: {
    Stepper,
  },
  data() {
    return {
      value: 0,
      items: [
        {
          label: "About Your Organization",
          icon: "k-i-cart",
        },
        {
          label: "Contacts",
          icon: "k-i-marker-pin-target",
        },
        {
          label: "Travel and Logistics",
          icon: "k-i-dollar",
        },
        {
          label: "Affiliates",
          icon: "k-i-preview",
        },
        {
          label: "Program-Specific",
          icon: "k-i-track-changes-accept",
        },
        {
          label: "Deposit",
          icon: "k-i-track-changes-accept",
        },
      ],
    };
  },
  methods: {
    handleChange(e) {
      this.value = e.value;
    },
  },
};
</script>
